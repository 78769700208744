import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './landing-page/LandingPage';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';


function App() {
  return (
    <>
    <LandingPage />
    {/* <Latex>We give illustrations for the {1 + 2} processes $e^+e^-$, gluon-gluon and $\\gamma\\gamma \\to W t\\bar b$.</Latex> */}
    {/* <iframe src="https://open.spotify.com/embed/track/3CqYwDWHw3pi1m8RAd2qCP?utm_source=generator" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe> */}
    </>

  );
}

export default App;
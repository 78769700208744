import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { visuallyHidden } from "@mui/utils";

import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";

import links from "../../staticLinks.js";
import SitemarkIcon from "./SitemarkIcon";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="#">COARSINGER&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <SitemarkIcon />
            <Typography
              variant="body2"
              fontWeight={600}
              gutterBottom
              sx={{ mt: 2 }}
            >
              Für den Newsletter anmelden
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Erhalten Sie die neuesten Nachrichten über Kaffee und seinen
              Einfluss auf Mitarbeiterproduktivität.
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <InputLabel htmlFor="email-newsletter" sx={visuallyHidden}>
                Email
              </InputLabel>
              <TextField
                id="email-newsletter"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Geben Sie Ihre E-Mail-Adresse ein"
                placeholder="Ihre E-Mail-Adresse"
                slotProps={{
                  htmlInput: {
                    autoComplete: "off",
                    "aria-label": "Enter your email address",
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ flexShrink: 0 }}
              >
                Abonnieren
              </Button>
            </Stack>
          </Box>
        </Box>
        {/* 

        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight="medium">Product</Typography>
          <Link color="text.secondary" variant="body2" href="#">Features</Link>
          <Link color="text.secondary" variant="body2" href="#">Testimonials</Link>
          <Link color="text.secondary" variant="body2" href="#">Highlights</Link>
          <Link color="text.secondary" variant="body2" href="#">Pricing</Link>
          <Link color="text.secondary" variant="body2" href="#">FAQs</Link>
        </Box>
 */}

        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight="medium">
            Unser Unternehmen
          </Typography>
          <Link color="text.secondary" variant="body2" target="_blank" rel="noopener" href={links.about} >
            Über Uns
          </Link>
          <Link color="text.secondary" variant="body2" target="_blank" rel="noopener" href={links.careers}>
            Karriere
          </Link>
          <Link color="text.secondary" variant="body2" href="#"></Link>
        </Box>

        {/*         
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight="medium">Legal</Typography>
          <Link color="text.secondary" variant="body2" href="#">Terms</Link>
          <Link color="text.secondary" variant="body2" href="#">Privacy</Link>
          <Link color="text.secondary" variant="body2" href="#">Contact</Link>
        </Box> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" target="_blank" rel="noopener" href={links.privacy}>
            Datenschutz
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" target="_blank" rel="noopener" href={links.terms}>
            Allgemeine Geschäftsbedingungen
          </Link>
          <Copyright />
        </div>

        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{ color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            href="#"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="#"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="#"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 200, width: 200, mr: 2 }}>
<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
	 x="0px" y="0px" viewBox="150 -40 792 612" overflow="visible" xmlSpace="preserve">
    <defs><style>@import url(https://fonts.cdnfonts.com/css/franklin-gothic);</style></defs>

<g>
	<g>
		<g>
			<g>
				<path fill="#FAF9F8" d="M164.42,254.72c-2.15-3.5-4.63-6.77-6.31-10.4c-4.36-9.47-1.51-19.42,5.97-25.87
					C175.07,228.78,175.66,243.65,164.42,254.72z"/>
				<path fill="#FEFDFD" d="M237.11,298.77v1.15c-0.1-0.38-0.19-0.76-0.29-1.15H237.11z"/>
				<path fill="#231A15" d="M209.85,257.5c-0.63-4.01-2.12-7.88-3.21-11.81c-0.14-0.27-0.28-0.55-0.42-0.82
					c-0.56-1.15-1.12-2.3-1.68-3.45c-0.05-0.12-0.1-0.24-0.15-0.36c-0.46-0.76-0.91-1.52-1.37-2.28c-0.42-0.63-0.84-1.26-1.26-1.89
					c-1.38-1.74-2.76-3.48-4.15-5.23c-0.11-0.09-0.22-0.18-0.32-0.28c-7.5-8.05-16.78-12.76-27.55-14.59
					c-1.13-0.19-1.73-0.59-2.26-1.56c-1.04-1.91-2.23-3.74-3.47-5.79c-1.18,2.1-2.25,4-3.33,5.91c-0.43,0.76-0.89,1.27-1.94,1.39
					c-5.02,0.58-9.83,2.01-14.28,4.39c-16.07,8.59-25.2,21.94-26.81,40.16c-1.05,11.89,2.02,22.72,9.16,32.33
					c4.4,5.92,9.98,10.48,16.44,13.99c1.44,0.68,2.82,1.54,4.33,2.01c4.07,1.27,8.19,2.58,12.49,2.59
					c4.31,0.02,8.63-0.35,12.94-0.56c0.11-0.04,0.23-0.08,0.34-0.13c0.36-0.06,0.72-0.11,1.08-0.17c0.4-0.1,0.8-0.2,1.2-0.3
					l-0.02,0.01c0.01,0,0.01-0.01,0.02-0.01c0.1-0.05,0.2-0.09,0.3-0.14c1.14-0.33,2.28-0.65,3.42-0.98
					c0.1-0.06,0.21-0.11,0.31-0.18c0.75-0.29,1.51-0.58,2.26-0.87c0.3-0.12,0.61-0.24,0.91-0.37c0.37-0.17,0.74-0.34,1.1-0.52
					c0.3-0.13,0.6-0.25,0.9-0.38c0.19-0.1,0.38-0.19,0.57-0.29c0.19-0.11,0.37-0.22,0.56-0.33c0.3-0.16,0.59-0.33,0.89-0.49
					c0.14-0.11,0.28-0.24,0.44-0.34c5.47-3.37,10.2-7.54,13.95-12.78C209.01,282.6,211.9,270.58,209.85,257.5z M164.08,218.45
					c10.99,10.33,11.58,25.2,0.34,36.27c-2.15-3.5-4.63-6.77-6.31-10.4C153.75,234.85,156.6,224.89,164.08,218.45z M179.53,233.48
					c0.01-0.12,0.02-0.24,0.03-0.35c0.05,0.07,0.1,0.13,0.15,0.19c2.57,1.78,5.33,3.34,7.68,5.38c5.71,4.94,9.46,11.19,11.35,18.52
					c0.08,0.32,0.16,0.65,0.2,0.98c0.03,0.28,0.01,0.56,0.01,1.07c-8.26-4.77-16.66-4.81-25.53-1.05
					C179.28,250.65,181.78,242.63,179.53,233.48z M177.21,261.51c7.42-2.67,14.44-1.71,21.11,2.41c1.07,0.65,1.63,1.33,1.31,2.62
					c-0.12,0.49-0.02,1.04-0.02,1.9c-10.74-6.8-20.82-5.78-30.35,1.6c-0.81-0.84-1.52-1.58-2.31-2.41
					C169.83,264.96,173.33,262.9,177.21,261.51z M150.12,295.02c-1.11,0.42-2.25,0.85-3.24,1.48c-1.1,0.7-1.9,0.51-2.79-0.28
					c-0.7-0.63-1.48-1.18-2.44-1.94c5.54-1.03,10.22-4.31,15.88-3.91c1.09,0.08,1.84,0.42,2.64,1.13c2.88,2.56,5.8,5.08,8.78,7.52
					c0.93,0.76,2.12,1.22,3.39,1.93c-5.08,1.35-9.97,1.6-15.23,0.25c2.3-1.42,4.39-2.72,6.56-4.06
					C159.54,293.49,154.98,293.21,150.12,295.02z M145.12,277.06c0.07,0.17,0.14,0.34,0.22,0.52c-3.38,1.64-6.76,3.28-10.15,4.91
					c-0.21,0.1-0.45,0.15-0.68,0.18c-2.28,0.34-2.41,0.26-3.38-2.14C135.44,277.85,139.95,276.13,145.12,277.06z M129.55,275.99
					c-0.23-0.88-0.47-1.6-0.59-2.34c-0.04-0.24,0.18-0.63,0.4-0.78c8.82-6.05,18.32-6.27,26.63,1.54c0.02,0.02-0.01,0.08-0.02,0.19
					c-2.23,0.14-4.33,0.22-6.55-0.81c-5.51-2.56-11.06-1.78-16.48,0.59C131.86,274.85,130.8,275.39,129.55,275.99z M143.81,284.36
					c4.05-1.26,7.88-0.47,11.48,1.74c-3.89-0.16-7.36,1.45-10.98,2.44c-1.91,0.52-3.92,0.69-5.88,1.05
					c-1.78,0.32-2.87-0.57-3.69-2.03C137.77,286.47,140.76,285.31,143.81,284.36z M165.39,290.37c-2.47-2.52-4.99-5.01-7.72-7.25
					c-1.8-1.48-4-2.48-5.94-3.65c2.42-0.58,5.06-0.41,7.66,0.57c2.63,0.98,4.58,2.88,6.49,4.83c2.98,3.03,5.87,6.23,9.82,8
					c2.71,1.21,5.6,2.02,8.44,3.01c-1.93,1.46-4.06,2.39-6.92,1.64C172.53,296.28,168.74,293.78,165.39,290.37z M170.52,283.35
					c-4.67-4.58-9.22-9.29-13.99-13.77c-3.26-3.06-7.22-4.98-11.72-5.56c-5.32-0.68-10.2,0.72-14.79,3.35
					c-0.49,0.28-0.98,0.56-1.48,0.86c-0.98-2.75,0.19-4.09,2.54-5.29c8.16-4.18,16.03-3.79,23.84,0.85
					c5.28,3.14,9.49,7.51,13.73,11.85c2.34,2.4,4.68,4.8,7.21,6.97c4.6,3.95,9.79,6.6,16.12,6.35c-1.48,2.44-2.95,3.06-5.57,2.76
					C180.01,291.01,174.98,287.72,170.52,283.35z M155.34,225.23c0-0.11,0-0.21-0.01-0.32c0.06,0.03,0.12,0.06,0.18,0.09
					C155.46,225.08,155.4,225.15,155.34,225.23z M155.34,225.25c0,0.02,0,0.05,0,0.07c-0.02,0-0.03,0-0.05,0
					c-1.54,3.7-2.33,7.53-2.15,11.55c0.25,5.51,2.52,10.22,5.64,14.62c1.22,1.71,2.42,3.43,3.58,5.18c1.61,2.45,2.22,5.15,1.81,8.2
					c-0.3-0.15-0.59-0.26-0.83-0.43c-5.7-4.1-10.74-8.78-13.29-15.56c-1.89-5.02-2.67-10.16-1.03-15.41
					c1.01-3.22,3.06-5.81,5.54-8.04c0.04-0.03,0.08-0.06,0.14-0.07c0.03-0.01,0.06-0.02,0.09-0.02c0.03-0.01,0.06-0.01,0.1-0.01
					c0.03,0,0.07,0,0.1,0c0.07,0,0.14,0,0.2-0.01c0.03,0,0.07,0,0.1-0.01c0-0.01,0.01-0.02,0.01-0.02
					C155.31,225.28,155.33,225.26,155.34,225.25z M154.47,258.23c-8.67-3.77-17.14-3.71-25.66,1.05
					c2.58-11.61,9.1-19.96,19.45-26.08C146.27,242.79,148.87,250.84,154.47,258.23z M178.17,279.05c3.53-1.94,7.11-2.73,10.98-2
					c-2.54,1.26-4.99,2.5-7.47,3.67c-0.37,0.17-1,0.1-1.38-0.1C179.6,280.23,179,279.67,178.17,279.05z M186.08,283.99
					c-0.02-0.21-0.04-0.42-0.06-0.63c3.37-1.82,6.77-3.58,10.81-3.49c-0.64,2.88-1.48,3.89-4.15,4.15
					C190.5,284.23,188.28,284.02,186.08,283.99z M194.08,274.01c-6.26-2.7-12.34-2.29-18.22,1.08c-0.8,0.46-1.33,0.53-1.94-0.2
					c-0.42-0.51-0.95-0.92-1.63-1.57c6.28-4.59,12.85-6.37,20.18-3.6c1.95,0.74,3.76,1.84,5.65,2.73c0.97,0.45,1.08,1.24,0.65,1.99
					c-0.2,0.35-0.99,0.61-1.44,0.53C196.22,274.77,195.11,274.45,194.08,274.01z M192.09,297.23c-6.96,6.54-15.43,10.2-25.03,10.75
					c-15.45,0.88-27.89-5.08-37.13-17.34c-6.52-8.66-8.87-18.68-8.02-29.45c0.86-10.92,5.31-20.23,13.02-27.93
					c5.55-5.54,12.13-9.37,19.79-11.28c0.36-0.09,0.74-0.14,1.1-0.21c0.08,0.15,0.16,0.3,0.24,0.45c-1.45,0.75-2.84,1.64-4.35,2.24
					c-15.36,6.03-25.04,17.01-27.82,33.3c-2.34,13.64,1.03,26.06,10.79,36.21c16.34,17,42.19,16.41,57.81,0.77
					c9.65-9.66,13.64-21.45,11.89-34.9c-2.28-17.52-12.16-29.18-28.59-35.44c-1-0.38-1.84-1.21-2.75-1.83
					c0.09-0.18,0.17-0.37,0.26-0.55c1.66,0.54,3.36,0.97,4.96,1.64c13.09,5.5,22.25,14.85,26.09,28.58
					C209.18,269.49,205.31,284.81,192.09,297.23z"/>
				<path fill="#EAE7E4" d="M156.06,222.21c-1.45,0.75-2.84,1.64-4.35,2.24c-15.35,6.03-25.04,17.01-27.82,33.3
					c-2.33,13.65,1.03,26.06,10.8,36.22c16.34,17,42.19,16.41,57.81,0.77c9.65-9.67,13.64-21.45,11.89-34.9
					c-2.28-17.52-12.16-29.18-28.59-35.44c-1-0.38-1.84-1.21-2.75-1.83c0.09-0.18,0.17-0.37,0.26-0.55
					c1.66,0.54,3.36,0.97,4.96,1.64c13.09,5.51,22.25,14.85,26.09,28.58c4.82,17.26,0.96,32.58-12.26,45
					c-6.96,6.54-15.43,10.2-25.03,10.75c-15.45,0.88-27.89-5.08-37.13-17.34c-6.52-8.66-8.87-18.68-8.02-29.45
					c0.86-10.92,5.31-20.22,13.02-27.93c5.54-5.54,12.13-9.37,19.78-11.28c0.36-0.09,0.74-0.14,1.11-0.21
					C155.9,221.91,155.98,222.06,156.06,222.21z"/>
				<path fill="#F8F6F5" d="M179.71,233.32c2.57,1.78,5.33,3.34,7.68,5.37c5.71,4.94,9.45,11.19,11.34,18.52
					c0.08,0.32,0.16,0.65,0.2,0.98c0.03,0.28,0.01,0.56,0.01,1.08c-8.26-4.77-16.66-4.81-25.53-1.05
					c5.87-7.56,8.37-15.59,6.11-24.74C179.59,233.43,179.66,233.38,179.71,233.32z"/>
				<path fill="#F7F6F5" d="M128.81,259.27c2.58-11.61,9.1-19.96,19.45-26.08c-2,9.59,0.61,17.65,6.21,25.03
					C145.8,254.45,137.33,254.52,128.81,259.27z"/>
				<path fill="#F4F2F0" d="M128.54,268.23c-0.99-2.75,0.19-4.09,2.54-5.29c8.16-4.18,16.03-3.79,23.84,0.85
					c5.29,3.14,9.49,7.51,13.73,11.86c2.33,2.39,4.68,4.8,7.21,6.97c4.6,3.95,9.79,6.59,16.12,6.35c-1.47,2.44-2.95,3.06-5.56,2.76
					c-6.41-0.72-11.43-4.01-15.89-8.38c-4.67-4.58-9.22-9.29-13.99-13.77c-3.26-3.06-7.22-4.98-11.71-5.55
					c-5.32-0.68-10.2,0.72-14.79,3.35C129.53,267.65,129.05,267.94,128.54,268.23z"/>
				<path fill="#F5F3F2" d="M155.3,225.29c-1.55,3.7-2.34,7.54-2.16,11.57c0.25,5.51,2.52,10.22,5.64,14.62
					c1.21,1.71,2.42,3.43,3.58,5.19c1.61,2.45,2.22,5.15,1.81,8.2c-0.3-0.16-0.59-0.26-0.83-0.43c-5.7-4.1-10.74-8.78-13.29-15.56
					c-1.89-5.02-2.67-10.16-1.03-15.41c1.01-3.22,3.06-5.8,5.54-8.04c0.16-0.15,0.51-0.08,0.78-0.12L155.3,225.29z"/>
				<path fill="#F3F1EF" d="M184.14,295.87c-1.93,1.46-4.06,2.39-6.92,1.64c-4.7-1.24-8.49-3.74-11.84-7.14
					c-2.47-2.52-4.99-5.01-7.72-7.25c-1.8-1.48-4-2.48-5.94-3.65c2.42-0.58,5.06-0.41,7.66,0.57c2.63,0.98,4.58,2.89,6.49,4.83
					c2.98,3.03,5.87,6.23,9.83,8C178.42,294.07,181.31,294.88,184.14,295.87z"/>
				<path fill="#F3F1EF" d="M199.61,268.43c-10.74-6.8-20.82-5.77-30.35,1.6c-0.81-0.84-1.52-1.58-2.31-2.41
					c2.88-2.66,6.38-4.72,10.26-6.12c7.42-2.67,14.44-1.71,21.11,2.41c1.07,0.66,1.63,1.33,1.31,2.62
					C199.51,267.03,199.61,267.57,199.61,268.43z"/>
				<path fill="#F1EFED" d="M172.33,300.95c-5.07,1.35-9.97,1.6-15.23,0.25c2.3-1.42,4.39-2.72,6.56-4.06
					c-4.13-3.66-8.68-3.94-13.54-2.13c-1.11,0.42-2.25,0.85-3.24,1.48c-1.1,0.7-1.9,0.51-2.79-0.28c-0.7-0.63-1.48-1.18-2.44-1.94
					c5.54-1.04,10.22-4.3,15.88-3.91c1.09,0.08,1.84,0.42,2.64,1.13c2.88,2.56,5.8,5.08,8.78,7.52
					C169.88,299.79,171.06,300.25,172.33,300.95z"/>
				<path fill="#F3F1EF" d="M172.29,273.31c6.28-4.59,12.85-6.37,20.19-3.6c1.95,0.74,3.76,1.84,5.65,2.73
					c0.96,0.46,1.08,1.24,0.65,1.99c-0.2,0.35-0.99,0.61-1.44,0.53c-1.1-0.19-2.21-0.51-3.24-0.96c-6.27-2.7-12.34-2.29-18.22,1.08
					c-0.79,0.46-1.33,0.53-1.94-0.2C173.49,274.38,172.97,273.96,172.29,273.31z"/>
				<path fill="#F2F0EE" d="M129.55,275.99c-0.23-0.88-0.47-1.6-0.58-2.34c-0.04-0.24,0.18-0.63,0.4-0.78
					c8.82-6.05,18.32-6.27,26.63,1.54c0.02,0.02-0.01,0.08-0.02,0.19c-2.23,0.14-4.33,0.22-6.55-0.81
					c-5.51-2.56-11.07-1.78-16.48,0.59C131.86,274.85,130.8,275.39,129.55,275.99z"/>
				<path fill="#F3F1EF" d="M155.29,286.09c-3.89-0.16-7.36,1.45-10.98,2.44c-1.91,0.52-3.92,0.69-5.88,1.05
					c-1.78,0.32-2.87-0.57-3.69-2.03c3.04-1.07,6.03-2.24,9.08-3.19C147.86,283.1,151.69,283.89,155.29,286.09z"/>
				<path fill="#F0EEEC" d="M131.13,280.52c4.31-2.67,8.82-4.39,13.99-3.46c0.07,0.17,0.14,0.34,0.22,0.52
					c-3.38,1.64-6.76,3.28-10.15,4.91c-0.21,0.1-0.45,0.15-0.69,0.18C132.23,283,132.09,282.92,131.13,280.52z"/>
				<path fill="#F0EEEC" d="M186.02,283.36c3.37-1.82,6.76-3.58,10.81-3.48c-0.64,2.89-1.48,3.89-4.15,4.15
					c-2.18,0.21-4.4,0-6.6-0.03C186.06,283.78,186.04,283.57,186.02,283.36z"/>
				<path fill="#EDEAE8" d="M189.15,277.05c-2.54,1.26-4.99,2.5-7.47,3.67c-0.37,0.17-1,0.1-1.38-0.1c-0.7-0.38-1.3-0.94-2.13-1.57
					C181.7,277.11,185.28,276.31,189.15,277.05z"/>
			</g>
		</g>
		<ellipse fill="none" stroke="#231A14" stroke-width="5" stroke-miterlimit="10" cx="164.32" cy="265.02" rx="46.25" ry="47.77"/>
	</g>
	<g>
		<g>
			<g>
				<path fill="#FAF9F8" d="M164.42,254.72c-2.15-3.5-4.63-6.77-6.31-10.4c-4.36-9.47-1.51-19.42,5.97-25.87
					C175.07,228.78,175.66,243.65,164.42,254.72z"/>
				<path fill="#FEFDFD" d="M237.11,298.77v1.15c-0.1-0.38-0.19-0.76-0.29-1.15H237.11z"/>
				<path fill="#231A15" d="M209.85,257.5c-0.63-4.01-2.12-7.88-3.21-11.81c-0.14-0.27-0.28-0.55-0.42-0.82
					c-0.56-1.15-1.12-2.3-1.68-3.45c-0.05-0.12-0.1-0.24-0.15-0.36c-0.46-0.76-0.91-1.52-1.37-2.28c-0.42-0.63-0.84-1.26-1.26-1.89
					c-1.38-1.74-2.76-3.48-4.15-5.23c-0.11-0.09-0.22-0.18-0.32-0.28c-7.5-8.05-16.78-12.76-27.55-14.59
					c-1.13-0.19-1.73-0.59-2.26-1.56c-1.04-1.91-2.23-3.74-3.47-5.79c-1.18,2.1-2.25,4-3.33,5.91c-0.43,0.76-0.89,1.27-1.94,1.39
					c-5.02,0.58-9.83,2.01-14.28,4.39c-16.07,8.59-25.2,21.94-26.81,40.16c-1.05,11.89,2.02,22.72,9.16,32.33
					c4.4,5.92,9.98,10.48,16.44,13.99c1.44,0.68,2.82,1.54,4.33,2.01c4.07,1.27,8.19,2.58,12.49,2.59
					c4.31,0.02,8.63-0.35,12.94-0.56c0.11-0.04,0.23-0.08,0.34-0.13c0.36-0.06,0.72-0.11,1.08-0.17c0.4-0.1,0.8-0.2,1.2-0.3
					l-0.02,0.01c0.01,0,0.01-0.01,0.02-0.01c0.1-0.05,0.2-0.09,0.3-0.14c1.14-0.33,2.28-0.65,3.42-0.98
					c0.1-0.06,0.21-0.11,0.31-0.18c0.75-0.29,1.51-0.58,2.26-0.87c0.3-0.12,0.61-0.24,0.91-0.37c0.37-0.17,0.74-0.34,1.1-0.52
					c0.3-0.13,0.6-0.25,0.9-0.38c0.19-0.1,0.38-0.19,0.57-0.29c0.19-0.11,0.37-0.22,0.56-0.33c0.3-0.16,0.59-0.33,0.89-0.49
					c0.14-0.11,0.28-0.24,0.44-0.34c5.47-3.37,10.2-7.54,13.95-12.78C209.01,282.6,211.9,270.58,209.85,257.5z M164.08,218.45
					c10.99,10.33,11.58,25.2,0.34,36.27c-2.15-3.5-4.63-6.77-6.31-10.4C153.75,234.85,156.6,224.89,164.08,218.45z M179.53,233.48
					c0.01-0.12,0.02-0.24,0.03-0.35c0.05,0.07,0.1,0.13,0.15,0.19c2.57,1.78,5.33,3.34,7.68,5.38c5.71,4.94,9.46,11.19,11.35,18.52
					c0.08,0.32,0.16,0.65,0.2,0.98c0.03,0.28,0.01,0.56,0.01,1.07c-8.26-4.77-16.66-4.81-25.53-1.05
					C179.28,250.65,181.78,242.63,179.53,233.48z M177.21,261.51c7.42-2.67,14.44-1.71,21.11,2.41c1.07,0.65,1.63,1.33,1.31,2.62
					c-0.12,0.49-0.02,1.04-0.02,1.9c-10.74-6.8-20.82-5.78-30.35,1.6c-0.81-0.84-1.52-1.58-2.31-2.41
					C169.83,264.96,173.33,262.9,177.21,261.51z M150.12,295.02c-1.11,0.42-2.25,0.85-3.24,1.48c-1.1,0.7-1.9,0.51-2.79-0.28
					c-0.7-0.63-1.48-1.18-2.44-1.94c5.54-1.03,10.22-4.31,15.88-3.91c1.09,0.08,1.84,0.42,2.64,1.13c2.88,2.56,5.8,5.08,8.78,7.52
					c0.93,0.76,2.12,1.22,3.39,1.93c-5.08,1.35-9.97,1.6-15.23,0.25c2.3-1.42,4.39-2.72,6.56-4.06
					C159.54,293.49,154.98,293.21,150.12,295.02z M145.12,277.06c0.07,0.17,0.14,0.34,0.22,0.52c-3.38,1.64-6.76,3.28-10.15,4.91
					c-0.21,0.1-0.45,0.15-0.68,0.18c-2.28,0.34-2.41,0.26-3.38-2.14C135.44,277.85,139.95,276.13,145.12,277.06z M129.55,275.99
					c-0.23-0.88-0.47-1.6-0.59-2.34c-0.04-0.24,0.18-0.63,0.4-0.78c8.82-6.05,18.32-6.27,26.63,1.54c0.02,0.02-0.01,0.08-0.02,0.19
					c-2.23,0.14-4.33,0.22-6.55-0.81c-5.51-2.56-11.06-1.78-16.48,0.59C131.86,274.85,130.8,275.39,129.55,275.99z M143.81,284.36
					c4.05-1.26,7.88-0.47,11.48,1.74c-3.89-0.16-7.36,1.45-10.98,2.44c-1.91,0.52-3.92,0.69-5.88,1.05
					c-1.78,0.32-2.87-0.57-3.69-2.03C137.77,286.47,140.76,285.31,143.81,284.36z M165.39,290.37c-2.47-2.52-4.99-5.01-7.72-7.25
					c-1.8-1.48-4-2.48-5.94-3.65c2.42-0.58,5.06-0.41,7.66,0.57c2.63,0.98,4.58,2.88,6.49,4.83c2.98,3.03,5.87,6.23,9.82,8
					c2.71,1.21,5.6,2.02,8.44,3.01c-1.93,1.46-4.06,2.39-6.92,1.64C172.53,296.28,168.74,293.78,165.39,290.37z M170.52,283.35
					c-4.67-4.58-9.22-9.29-13.99-13.77c-3.26-3.06-7.22-4.98-11.72-5.56c-5.32-0.68-10.2,0.72-14.79,3.35
					c-0.49,0.28-0.98,0.56-1.48,0.86c-0.98-2.75,0.19-4.09,2.54-5.29c8.16-4.18,16.03-3.79,23.84,0.85
					c5.28,3.14,9.49,7.51,13.73,11.85c2.34,2.4,4.68,4.8,7.21,6.97c4.6,3.95,9.79,6.6,16.12,6.35c-1.48,2.44-2.95,3.06-5.57,2.76
					C180.01,291.01,174.98,287.72,170.52,283.35z M155.34,225.23c0-0.11,0-0.21-0.01-0.32c0.06,0.03,0.12,0.06,0.18,0.09
					C155.46,225.08,155.4,225.15,155.34,225.23z M155.34,225.25c0,0.02,0,0.05,0,0.07c-0.02,0-0.03,0-0.05,0
					c-1.54,3.7-2.33,7.53-2.15,11.55c0.25,5.51,2.52,10.22,5.64,14.62c1.22,1.71,2.42,3.43,3.58,5.18c1.61,2.45,2.22,5.15,1.81,8.2
					c-0.3-0.15-0.59-0.26-0.83-0.43c-5.7-4.1-10.74-8.78-13.29-15.56c-1.89-5.02-2.67-10.16-1.03-15.41
					c1.01-3.22,3.06-5.81,5.54-8.04c0.04-0.03,0.08-0.06,0.14-0.07c0.03-0.01,0.06-0.02,0.09-0.02c0.03-0.01,0.06-0.01,0.1-0.01
					c0.03,0,0.07,0,0.1,0c0.07,0,0.14,0,0.2-0.01c0.03,0,0.07,0,0.1-0.01c0-0.01,0.01-0.02,0.01-0.02
					C155.31,225.28,155.33,225.26,155.34,225.25z M154.47,258.23c-8.67-3.77-17.14-3.71-25.66,1.05
					c2.58-11.61,9.1-19.96,19.45-26.08C146.27,242.79,148.87,250.84,154.47,258.23z M178.17,279.05c3.53-1.94,7.11-2.73,10.98-2
					c-2.54,1.26-4.99,2.5-7.47,3.67c-0.37,0.17-1,0.1-1.38-0.1C179.6,280.23,179,279.67,178.17,279.05z M186.08,283.99
					c-0.02-0.21-0.04-0.42-0.06-0.63c3.37-1.82,6.77-3.58,10.81-3.49c-0.64,2.88-1.48,3.89-4.15,4.15
					C190.5,284.23,188.28,284.02,186.08,283.99z M194.08,274.01c-6.26-2.7-12.34-2.29-18.22,1.08c-0.8,0.46-1.33,0.53-1.94-0.2
					c-0.42-0.51-0.95-0.92-1.63-1.57c6.28-4.59,12.85-6.37,20.18-3.6c1.95,0.74,3.76,1.84,5.65,2.73c0.97,0.45,1.08,1.24,0.65,1.99
					c-0.2,0.35-0.99,0.61-1.44,0.53C196.22,274.77,195.11,274.45,194.08,274.01z M192.09,297.23c-6.96,6.54-15.43,10.2-25.03,10.75
					c-15.45,0.88-27.89-5.08-37.13-17.34c-6.52-8.66-8.87-18.68-8.02-29.45c0.86-10.92,5.31-20.23,13.02-27.93
					c5.55-5.54,12.13-9.37,19.79-11.28c0.36-0.09,0.74-0.14,1.1-0.21c0.08,0.15,0.16,0.3,0.24,0.45c-1.45,0.75-2.84,1.64-4.35,2.24
					c-15.36,6.03-25.04,17.01-27.82,33.3c-2.34,13.64,1.03,26.06,10.79,36.21c16.34,17,42.19,16.41,57.81,0.77
					c9.65-9.66,13.64-21.45,11.89-34.9c-2.28-17.52-12.16-29.18-28.59-35.44c-1-0.38-1.84-1.21-2.75-1.83
					c0.09-0.18,0.17-0.37,0.26-0.55c1.66,0.54,3.36,0.97,4.96,1.64c13.09,5.5,22.25,14.85,26.09,28.58
					C209.18,269.49,205.31,284.81,192.09,297.23z"/>
				<path fill="#EAE7E4" d="M156.06,222.21c-1.45,0.75-2.84,1.64-4.35,2.24c-15.35,6.03-25.04,17.01-27.82,33.3
					c-2.33,13.65,1.03,26.06,10.8,36.22c16.34,17,42.19,16.41,57.81,0.77c9.65-9.67,13.64-21.45,11.89-34.9
					c-2.28-17.52-12.16-29.18-28.59-35.44c-1-0.38-1.84-1.21-2.75-1.83c0.09-0.18,0.17-0.37,0.26-0.55
					c1.66,0.54,3.36,0.97,4.96,1.64c13.09,5.51,22.25,14.85,26.09,28.58c4.82,17.26,0.96,32.58-12.26,45
					c-6.96,6.54-15.43,10.2-25.03,10.75c-15.45,0.88-27.89-5.08-37.13-17.34c-6.52-8.66-8.87-18.68-8.02-29.45
					c0.86-10.92,5.31-20.22,13.02-27.93c5.54-5.54,12.13-9.37,19.78-11.28c0.36-0.09,0.74-0.14,1.11-0.21
					C155.9,221.91,155.98,222.06,156.06,222.21z"/>
				<path fill="#F8F6F5" d="M179.71,233.32c2.57,1.78,5.33,3.34,7.68,5.37c5.71,4.94,9.45,11.19,11.34,18.52
					c0.08,0.32,0.16,0.65,0.2,0.98c0.03,0.28,0.01,0.56,0.01,1.08c-8.26-4.77-16.66-4.81-25.53-1.05
					c5.87-7.56,8.37-15.59,6.11-24.74C179.59,233.43,179.66,233.38,179.71,233.32z"/>
				<path fill="#F7F6F5" d="M128.81,259.27c2.58-11.61,9.1-19.96,19.45-26.08c-2,9.59,0.61,17.65,6.21,25.03
					C145.8,254.45,137.33,254.52,128.81,259.27z"/>
				<path fill="#F4F2F0" d="M128.54,268.23c-0.99-2.75,0.19-4.09,2.54-5.29c8.16-4.18,16.03-3.79,23.84,0.85
					c5.29,3.14,9.49,7.51,13.73,11.86c2.33,2.39,4.68,4.8,7.21,6.97c4.6,3.95,9.79,6.59,16.12,6.35c-1.47,2.44-2.95,3.06-5.56,2.76
					c-6.41-0.72-11.43-4.01-15.89-8.38c-4.67-4.58-9.22-9.29-13.99-13.77c-3.26-3.06-7.22-4.98-11.71-5.55
					c-5.32-0.68-10.2,0.72-14.79,3.35C129.53,267.65,129.05,267.94,128.54,268.23z"/>
				<path fill="#F5F3F2" d="M155.3,225.29c-1.55,3.7-2.34,7.54-2.16,11.57c0.25,5.51,2.52,10.22,5.64,14.62
					c1.21,1.71,2.42,3.43,3.58,5.19c1.61,2.45,2.22,5.15,1.81,8.2c-0.3-0.16-0.59-0.26-0.83-0.43c-5.7-4.1-10.74-8.78-13.29-15.56
					c-1.89-5.02-2.67-10.16-1.03-15.41c1.01-3.22,3.06-5.8,5.54-8.04c0.16-0.15,0.51-0.08,0.78-0.12L155.3,225.29z"/>
				<path fill="#F3F1EF" d="M184.14,295.87c-1.93,1.46-4.06,2.39-6.92,1.64c-4.7-1.24-8.49-3.74-11.84-7.14
					c-2.47-2.52-4.99-5.01-7.72-7.25c-1.8-1.48-4-2.48-5.94-3.65c2.42-0.58,5.06-0.41,7.66,0.57c2.63,0.98,4.58,2.89,6.49,4.83
					c2.98,3.03,5.87,6.23,9.83,8C178.42,294.07,181.31,294.88,184.14,295.87z"/>
				<path fill="#F3F1EF" d="M199.61,268.43c-10.74-6.8-20.82-5.77-30.35,1.6c-0.81-0.84-1.52-1.58-2.31-2.41
					c2.88-2.66,6.38-4.72,10.26-6.12c7.42-2.67,14.44-1.71,21.11,2.41c1.07,0.66,1.63,1.33,1.31,2.62
					C199.51,267.03,199.61,267.57,199.61,268.43z"/>
				<path fill="#F1EFED" d="M172.33,300.95c-5.07,1.35-9.97,1.6-15.23,0.25c2.3-1.42,4.39-2.72,6.56-4.06
					c-4.13-3.66-8.68-3.94-13.54-2.13c-1.11,0.42-2.25,0.85-3.24,1.48c-1.1,0.7-1.9,0.51-2.79-0.28c-0.7-0.63-1.48-1.18-2.44-1.94
					c5.54-1.04,10.22-4.3,15.88-3.91c1.09,0.08,1.84,0.42,2.64,1.13c2.88,2.56,5.8,5.08,8.78,7.52
					C169.88,299.79,171.06,300.25,172.33,300.95z"/>
				<path fill="#F3F1EF" d="M172.29,273.31c6.28-4.59,12.85-6.37,20.19-3.6c1.95,0.74,3.76,1.84,5.65,2.73
					c0.96,0.46,1.08,1.24,0.65,1.99c-0.2,0.35-0.99,0.61-1.44,0.53c-1.1-0.19-2.21-0.51-3.24-0.96c-6.27-2.7-12.34-2.29-18.22,1.08
					c-0.79,0.46-1.33,0.53-1.94-0.2C173.49,274.38,172.97,273.96,172.29,273.31z"/>
				<path fill="#F2F0EE" d="M129.55,275.99c-0.23-0.88-0.47-1.6-0.58-2.34c-0.04-0.24,0.18-0.63,0.4-0.78
					c8.82-6.05,18.32-6.27,26.63,1.54c0.02,0.02-0.01,0.08-0.02,0.19c-2.23,0.14-4.33,0.22-6.55-0.81
					c-5.51-2.56-11.07-1.78-16.48,0.59C131.86,274.85,130.8,275.39,129.55,275.99z"/>
				<path fill="#F3F1EF" d="M155.29,286.09c-3.89-0.16-7.36,1.45-10.98,2.44c-1.91,0.52-3.92,0.69-5.88,1.05
					c-1.78,0.32-2.87-0.57-3.69-2.03c3.04-1.07,6.03-2.24,9.08-3.19C147.86,283.1,151.69,283.89,155.29,286.09z"/>
				<path fill="#F0EEEC" d="M131.13,280.52c4.31-2.67,8.82-4.39,13.99-3.46c0.07,0.17,0.14,0.34,0.22,0.52
					c-3.38,1.64-6.76,3.28-10.15,4.91c-0.21,0.1-0.45,0.15-0.69,0.18C132.23,283,132.09,282.92,131.13,280.52z"/>
				<path fill="#F0EEEC" d="M186.02,283.36c3.37-1.82,6.76-3.58,10.81-3.48c-0.64,2.89-1.48,3.89-4.15,4.15
					c-2.18,0.21-4.4,0-6.6-0.03C186.06,283.78,186.04,283.57,186.02,283.36z"/>
				<path fill="#EDEAE8" d="M189.15,277.05c-2.54,1.26-4.99,2.5-7.47,3.67c-0.37,0.17-1,0.1-1.38-0.1c-0.7-0.38-1.3-0.94-2.13-1.57
					C181.7,277.11,185.28,276.31,189.15,277.05z"/>
			</g>
		</g>
		<ellipse fill="none" stroke="#231A14" stroke-width="5" stroke-miterlimit="10" cx="164.32" cy="265.02" rx="46.25" ry="47.77"/>
	</g>
</g>
<rect x="236.75" y="220.12" fill="none" width="482.88" height="52.63"/>
<text transform="matrix(1 0 0 1 236.7545 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">C</text>
<text transform="matrix(1 0 0 1 285.6354 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">O</text>
<text transform="matrix(1 0 0 1 337.8199 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">A</text>
<text transform="matrix(1 0 0 1 387.4225 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">R</text>
<text transform="matrix(1 0 0 1 441.0885 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">S</text>
<text transform="matrix(1 0 0 1 488.3356 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">I</text>
<text transform="matrix(1 0 0 1 513.2887 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">N</text>
<text transform="matrix(1 0 0 1 566.651 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">G</text>
<text transform="matrix(1 0 0 1 619.8229 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">E</text>
<text transform="matrix(1 0 0 1 665.1334 271.9961)" fill="#231A14" font-family="'Franklin Gothic'" font-size="77.7835px">R</text>
<rect x="218.99" y="280.77" fill="none" width="518.41" height="33.17"/>
<text transform="matrix(1 0 0 1 218.9912 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">p</text>
<text transform="matrix(1 0 0 1 243.3945 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">e</text>
<text transform="matrix(1 0 0 1 267.7979 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">r</text>
<text transform="matrix(1 0 0 1 284.4189 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">f</text>
<text transform="matrix(1 0 0 1 300.7354 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">o</text>
<text transform="matrix(1 0 0 1 325.4639 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">r</text>
<text transform="matrix(1 0 0 1 341.333 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">m</text>
<text transform="matrix(1 0 0 1 376.9326 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">a</text>
<text transform="matrix(1 0 0 1 401.3359 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">n</text>
<text transform="matrix(1 0 0 1 425.7803 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">c</text>
<text transform="matrix(1 0 0 1 447.542 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">e</text>
<text transform="matrix(1 0 0 1 471.9453 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">.</text>
<text transform="matrix(1 0 0 1 483.3848 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">m</text>
<text transform="matrix(1 0 0 1 518.9844 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">e</text>
<text transform="matrix(1 0 0 1 543.3877 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">e</text>
<text transform="matrix(1 0 0 1 567.791 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">t</text>
<text transform="matrix(1 0 0 1 583.2539 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">.</text>
<text transform="matrix(1 0 0 1 594.6934 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">c</text>
<text transform="matrix(1 0 0 1 616.4551 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">o</text>
<text transform="matrix(1 0 0 1 641.1836 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">f</text>
<text transform="matrix(1 0 0 1 658.333 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">f</text>
<text transform="matrix(1 0 0 1 674.4463 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">e</text>
<text transform="matrix(1 0 0 1 698.8496 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">e</text>
<text transform="matrix(1 0 0 1 723.2529 308.5225)" fill="#231A14" font-family="'Franklin Gothic'" font-size="41.6142px">.</text>

</svg>


    </SvgIcon>
  );
}


import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqData = [
  {
    panelId: "panel1",
    summary: <>Was ist so besonders an frisch geröstetem Kaffee?</>,
    details: (
      <Box>
        <Typography variant="body1">
          Frisch gerösteter Kaffee bietet ein überlegenes Aroma und Geschmack.
        </Typography>
        <Typography variant="body1">
          Frisch gerösteter Kaffee ist besonders, weil er ein{" "}
          <strong>überlegenes Aroma und Geschmack</strong> bietet, die man bei
          vorgefertigtem oder länger gelagertem Kaffee nicht findet.
        </Typography>
        <Typography variant="body1">
          Wenn Kaffee geröstet wird, durchläuft er eine Reihe von chemischen
          Veränderungen. Während dieses Prozesses werden die Aromen und
          Geschmacksprofile des Kaffees freigesetzt und entwickelt. Nach der
          Röstung beginnen diese Aromen jedoch allmählich zu verblassen. Daher
          ist frisch gerösteter Kaffee oft{" "}
          <strong>geschmackvoller und aromatischer</strong>.
        </Typography>
        <Typography variant="body1">
          Darüber hinaus ermöglicht frisch gerösteter Kaffee eine{" "}
          <strong>bessere Qualitätskontrolle</strong>. Sie können die Röststufe
          wählen, die Ihren Vorlieben entspricht, und sicherstellen, dass der
          Kaffee unter optimalen Bedingungen gelagert wird, um seine Frische zu
          bewahren.
        </Typography>
        <Typography variant="body1">
          Schließlich kann frisch gerösteter Kaffee dazu beitragen, die{" "}
          <strong>Motivation und Produktivität</strong> am Arbeitsplatz zu
          steigern. Ein guter Kaffee kann den Tag aufhellen und dazu beitragen,
          dass sich die Mitarbeiter wohler und geschätzt fühlen.
        </Typography>
      </Box>
    ),
  },
  {
    panelId: "panel2",
    summary: (
      <>
        Warum sollte ich mehr für Coarsinger&apos;s Kaffee ausgeben, wenn ich
        günstigen Kaffee für 12 € kaufen kann?
      </>
    ),
    details: (
      <Box>
        <Typography>
          TLDR: Coarsinger liefert Kaffee basierend auf dem individuellen
          Verbrauch jedes Büros, um maximale Frische zu gewährleisten.
        </Typography>
        <Typography variant="body1">
          Coarsinger&apos;s Kaffee bietet nicht nur einen großartigen Geschmack
          durch frische Röstung, sondern steigert auch die
          Mitarbeitermotivation. Die Investition in Qualität hat langfristige
          Vorteile, die die Kosten rechtfertigen.
        </Typography>
        <Typography variant="body1">
          Es ist verständlich, dass der Preis ein wichtiger Faktor bei der
          Entscheidung ist. Allerdings geht es bei Coarsinger&apos;s Kaffee um
          mehr als nur den Preis. Es geht um die Qualität des Kaffees und die
          positiven Auswirkungen, die er auf Ihre Mitarbeiter haben kann.
        </Typography>
        <Typography variant="body1">
          Unser Kaffee wird frisch geröstet und liefert daher ein überlegenes
          Geschmackserlebnis im Vergleich zu günstigerem Kaffee. Dies kann dazu
          beitragen, dass sich Ihre Mitarbeiter auf die Arbeit freuen und ihre
          Pausen mehr genießen. Darüber hinaus kann die Bereitstellung von
          hochwertigem Kaffee als Zeichen der Wertschätzung angesehen werden,
          was die Mitarbeitermotivation und -zufriedenheit steigern kann.
        </Typography>
        <Typography variant="body1">
          Zudem ist zu bedenken, dass die Kosten für Kaffee nur einen kleinen
          Teil der Gesamtkosten eines Unternehmens ausmachen. Die Investition in
          hochwertigen Kaffee kann jedoch einen großen Einfluss auf die
          Arbeitsmoral und Produktivität haben. Daher könnte man argumentieren,
          dass die Investition in besseren Kaffee tatsächlich kosteneffizient
          ist, wenn man die potenziellen Vorteile berücksichtigt.
        </Typography>
        <Typography variant="body1">
          Letztendlich ist die Entscheidung natürlich Ihnen überlassen. Wir bei
          Coarsinger glauben jedoch, dass die Vorteile unseres Kaffees die
          zusätzlichen Kosten mehr als rechtfertigen. Wir würden uns freuen,
          wenn Sie unseren Kaffee ausprobieren und die Vorteile selbst erleben
          könnten.
        </Typography>
      </Box>
    ),
  },
  {
    panelId: "panel4",
    summary: <>Wie oft wird der Kaffee geliefert?</>,
    details: (
      <Box>
        <Typography variant="body1">
          Bei Coarsinger verstehen wir, dass jeder Kunde einzigartig ist und
          unterschiedliche Bedürfnisse hat. Daher führen wir ein{" "}
          <strong>Qualifizierungsgespräch</strong> durch, um herauszufinden, wie
          viel Kaffee in Ihrem Büro getrunken wird. Basierend auf diesen
          Informationen erstellen wir einen{" "}
          <strong>individuellen Lieferplan</strong>.
        </Typography>
        <Typography variant="body1">
          Uns ist bewusst, dass die <strong>Frische des Kaffees</strong> für den
          Geschmack und das allgemeine Kaffeeerlebnis von entscheidender
          Bedeutung ist. Daher liefern wir den Kaffee so, dass er{" "}
          <strong>maximal 2 Tage</strong> steht, bevor er verbraucht wird. Auf
          diese Weise können Sie sicher sein, dass Sie und Ihre Mitarbeiter
          immer den frischesten und schmackhaftesten Kaffee genießen.
        </Typography>
        <Typography variant="body1">
          Unser Ziel bei Coarsinger ist es, Ihnen nicht nur hervorragenden
          Kaffee zu liefern, sondern auch einen außergewöhnlichen Service zu
          bieten, der auf Ihre spezifischen Bedürfnisse zugeschnitten ist.
        </Typography>
      </Box>
    ),
  },
  // Add more FAQ items as needed
];

function FAQItem({ panelId, summary, details }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={expanded === panelId} onChange={handleChange(panelId)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panelId}-content`}
        id={`${panelId}-header`}
      >
        <Typography component="h3" variant="subtitle2">
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          gutterBottom
          sx={{ maxWidth: { sm: "100%", md: "70%" } }}
        >
          {details}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Häufig gestellte Fragen
      </Typography>
      <Box sx={{ width: "100%" }}>
        {faqData.map((faq) => (
          <FAQItem
            key={faq.panelId}
            panelId={faq.panelId}
            summary={faq.summary}
            details={faq.details}
          />
        ))}
      </Box>
    </Container>
  );
}

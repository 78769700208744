const links ={
    terms : '#',
    privacy : '#',
    contact : '#',
    about : '#',
    services : '#',
    products : '#',
    login : '#',
    signup : '#',
    home : '#',
    careers : '#',
}
export default links;

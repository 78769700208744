import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import links from "../../staticLinks.js";

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  boxShadow:
    theme.palette.mode === "light"
      ? "0 0 12px 8px hsla(220, 25%, 80%, 0.2)"
      : "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
  outline: "1px solid",
  backgroundImage: `url(${
    theme.palette.mode === "light"
      ? "/static/images/templates/templates-images/hero-light.png"
      : "/static/images/templates/templates-images/hero-dark.png"
  })`,
  backgroundSize: "cover",
  outlineColor:
    theme.palette.mode === "light"
      ? "hsla(220, 25%, 80%, 0.5)"
      : "hsla(210, 100%, 80%, 0.1)",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
}));

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)"
            : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: "100%", sm: "70%" } }}
        >
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: "inherit",
                color: (theme) =>
                  theme.palette.mode === "light"
                ? "primary.main"
                : "primary.light",
                fontSize: "clamp(2rem, 8vw, 2.5rem)",
              }}
              >
              Premium Kaffee für Premium Mitarbeiter
            </Typography>


          <Typography
            variant="h2"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
            }}
            >
            Performance.&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: "inherit",
                color: (theme) =>
                  theme.palette.mode === "light"
                ? "primary.main"
                : "primary.light",
              }}
              >
              Meet.
            </Typography>
            &nbsp;Coffee.
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ width: { sm: "100%", md: "80%" } }}
            >
            Entdecken Sie die Welt guten frischen Kaffees.
            Noch während der Reifezeit auf dem Weg zu Ihnen.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          >
            <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
              Email
            </InputLabel>
            <TextField
              id="email-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="E-Mail Adresse eingeben"
              slotProps={{
                htmlInput: {
                  autoComplete: "off",
                  "aria-label": "Enter your email address",
                },
              }}
            />
            <Button variant="contained" color="primary">
              Jetzt starten
            </Button>
          </Stack>
          <Typography variant="caption" textAlign="center">
            Indem Sie auf &quot;Jetzt starten&quot; klicken, akzeptieren Sie
            unsere&nbsp;
            <Link href={links.terms} target="_blank" rel="noopener">
              AGB&apos;s
            </Link>
            .
          </Typography>
        </Stack>
        {/* <StyledBox id="image" /> */}
      </Container>
    </Box>
  );
}
